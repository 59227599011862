import "./src/styles/front/theme-3_1.css"
import "nouislider/distribute/nouislider.css"
import "./src/styles/style.css"
import "animate.css"
import netlifyIdentity from "netlify-identity-widget"
import CookieManager from "./src/utils/cookieManager"
import EventEmitter from "./src/utils/eventEmitter"
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

export function onClientEntry() {
  window.cookieManager = new CookieManager()
  window.eventEmitter = new EventEmitter()
  window.netlifyIdentity = netlifyIdentity

  // Import datadog logs & rum
  const datadogOptions = {
    env: process.env.NODE_ENV,
    service: "landing-page-client",
    site: "datadoghq.eu",
    forwardConsoleLogs: "all",
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  }
  const logsParams = {
    ...datadogOptions,
    clientToken: "pubac677107ba255a435898fd3192febf6d",
  }
  const rumParams = {
    ...datadogOptions,
    applicationId: "d536f343-a552-4b9f-ba74-49ac4822fa6b",
    clientToken: "pubac677107ba255a435898fd3192febf6d",
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
  }

  import("@datadog/browser-logs")
    .then(ddBrowserLogs => {
      ddBrowserLogs.datadogLogs.init(logsParams)
      window.datadogLogs = ddBrowserLogs.datadogLogs
    })
    .catch(error =>
      console.error("Failed to load @Datadog/browser-logs", error)
    )

  import("@datadog/browser-rum")
    .then(ddBrowserRUM => {
      ddBrowserRUM.datadogRum.init(rumParams)
      window.datadogRum = ddBrowserRUM.datadogRum
    })
    .catch(error => console.error("Failed to load @Datadog/browser-rum", error))
}
