import Cookie from "cookie-universal"

class CookieManager {
  #cookies
  #cookieDurationConfig

  constructor() {
    this.#cookies = Cookie()
    this.#cookieDurationConfig = {
      pageUserLanded: 4, // Duration in hours for pageUserLanded cookie
    }
  }

  get(cookieName) {
    return this.#cookies.get(cookieName)
  }

  set(cookieName, cookieValue) {
    const isDevelopment = process.env.NODE_ENV !== "production"

    const numberOfHours = this.#cookieDurationConfig[cookieName] || 24
    this.#cookies.set(cookieName, cookieValue, {
      path: "/",
      maxAge: 60 * 60 * numberOfHours,
      httpOnly: false,
      domain: isDevelopment ? undefined : ".wealthyhood.com",
      sameSite: "strict",
      secure: !isDevelopment,
    })
  }
}

export default CookieManager
